@import url("https://fonts.googleapis.com/css?family=Fira+Sans");
@import "../node_modules/tachyons/css/tachyons.min.css";

html,
body {
  background: orangered;
  height: 100%;
}

body {
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

nav {
  border-color: #111;
}

input:focus {
  outline: none;
}
